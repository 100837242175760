<template>
  <div class="w-m-content">
    <div class="w-m-header">
      <p class="font-14 font-bold no-margin" style="color: #293e52">
        Importação via upload de arquivo
      </p>
      <div class="m-t-xs font-13 text-grey-7" style="color: #748396; padding-right: 20px; line-height: 1">
        Utilize planilhas excel, csv ou json.
      </div>
    </div>
    <div v-if="null === success" class="w-m-body e-input-modern flex items-center center justify-center">
      <div v-if="!loading" class="text-center">
        <input type="file" id="input" ref="input" />
      </div>
      <div v-else class="text-center text-3x">
        <u-spinner-hourglass />
        <p>Aguarde, estamos analisando o arquivo, esta operação pode demorar alguns minutos.</p>
      </div>
    </div>
    <div v-else class="w-m-body e-input-modern flex items-center center justify-center">
      <div class="text-center">
        <p><i class="fa fa-check text-positive text-3x" /></p>
        <p><strong>Importação registrada com sucesso!</strong> Abrindo importação...</p>
      </div>
    </div>
    <div class="w-m-footer text-right">
      <u-btn :disable="loading" type="button" :label="null === success ? 'Cancelar' : 'Fechar'" color="grey-6"
             @click="$uloc.window.close($root.wid)"
             class="b-radius-3px m-r" />
      <u-btn v-if="null === success" type="submit" :disable="loading" label="Analisar arquivo" color="primary"
             @click="uploadAnalise" class="b-radius-3px" />
    </div>
  </div>
</template>

<script>
import {fileToBase64} from '@/utils/fileToBase64'
import {uploadAquivo} from '@/domain/importador/services'
import {USpinnerHourglass} from 'uloc-vue'
import windowImportacao from '../../windows/windowImportacao'

export default {
  name: 'ImportacaoArquivo',
  components: {USpinnerHourglass},
  props: ['module', 'entityId'],
  data () {
    return {
      loading: false,
      success: null
    }
  },
  methods: {
    uploadAnalise () {
      console.log(this.$refs.input.files)
      if (!this.$refs.input.files || !this.$refs.input.files.length) {
        alert('Selecione um arquivo para analisar e migrar.')
        return
      }

      const file = this.$refs.input.files[0]
      file.filename = file.name
      console.log(file)

      this.loading = true

      fileToBase64(file)
        .then(_file => {
          uploadAquivo({
            data: _file,
            module: this.module,
            entityId: this.entityId,
            ...file
          })
            .then(response => {
              this.loading = false
              this.success = true
              console.log(response.data)
              this.$uloc.window.emit(this.$root.wid, 'created')
              setTimeout(() => {
                this.windowImportacao(response.data.id)
              })
              // resolve(true)
            })
            .catch(error => {
              this.loading = false
              this.alertApiError(error.response)
              // resolve(false)
            })
        })
    },
    windowImportacao
  }
}
</script>

<style scoped>

</style>
